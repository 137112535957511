// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    h2: {
      fontSize: "2rem", // Tamanho padrão
      "@media (min-width:600px)": {
        fontSize: "1rem",
      },
      "@media (min-width:960px)": {
        fontSize: "3rem",
      },
      "@media (min-width:1280px)": {
        fontSize: "3.5rem",
      },
    },
  },
});

export default theme;
