//import logo from "./logo.svg";
import "./App.css";
//import Form from "./components/Form";
//import FormDataDisplay from "./components/FormDysplay";
//import FormPreview from "./components/FormPreview";
import FormPrincipal from "./components/FormData";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/Theme";

const App = () => {
  return (
    // <div>
    //   <FormPrincipal />
    //   {/* <FormPreview /> */}
    //   {/* <FormDataDisplay id={31} /> */}
    // </div>
    <ThemeProvider theme={theme}>
      <FormPrincipal />
    </ThemeProvider>
  );
};

export default App;
