// src/components/Form.js
import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";

import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Card,
  CardMedia,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@mui/material";

const FormPrincipal = () => {
  const [formData, setFormData] = useState({
    OSnumber: "",
    email: "",
    mensagem: "",
    foto: null,
    selectServico: "",
    checkboxAntena: false,
    utilizadoMateriais: "",
    trocaEquipamento: "",
    cienteGarantia: "",
    apresentouDefeito: "",
    selectDefeitoOnde: "",
    ApontAntena: false,
    TrocaLNB: false,
    TrocaAntena: false,
    ConectLNB: false,
    TrocaLocAntena: false,
    DefOndeOutros: false,
    acaoDefeito: "",
    CabosConectores: false,
  });

  const [borderColor, setBorderClass] = useState("");

  useEffect(() => {
    if (formData.OSnumber.length !== 9) {
      // Verifica se o campo está vazio
      setBorderClass("red");
    } else {
      setBorderClass("green");
    }
  }, [formData.OSnumber]);

  const [preview, setPreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, files, checked } = e.target;
    if (name === "foto") {
      const file = files[0];
      if (file) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: file,
        }));

        // Gerar uma URL de pré-visualização para o arquivo selecionado
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        // Se nenhum arquivo for selecionado, redefina a pré-visualização
        setFormData((prevData) => ({
          ...prevData,
          [name]: null,
        }));
        setPreview(null);
      }
    } else if (name === "checkboxAntena") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (name === "TrocaLNB") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (name === "TrocaLocAntena") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (name === "DefOndeOutros") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (name === "ApontAntena") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (name === "ConectLNB") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (name === "TrocaAntena") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (name === "CabosConectores") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSubmit = new FormData();
    const keys = [
      "OSnumber",
      "email",
      "mensagem",
      "selectServico",
      "checkboxAntena",
      "utilizadoMateriais",
      "trocaEquipamento",
      "cienteGarantia",
      "apresentouDefeito",
      "detalheDefeito",
      "selectDefeitoOnde",
      "ApontAntena",
      "TrocaLNB",
      "TrocaAntena",
      "ConectLNB",
      "TrocaLocAntena",
      "DefOndeOutros",
      "acaoDefeito",
    ];

    keys.forEach((key) => formDataToSubmit.append(key, formData[key]));

    if (formData.foto) {
      formDataToSubmit.append("foto", formData.foto);
    }

    try {
      const response = await fetch("https://localhost:32776/api/DadosServicos/Upload", {
        method: "POST",
        body: formDataToSubmit,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error details:", errorText);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    // <Container maxWidth="sm">
    <Container
      sx={{
        margin: "0 auto", // Centraliza horizontalmente
        padding: 0,
        width: "100%", // Garante que o Container ocupe 100% da largura
        maxWidth: "sm", // Define um tamanho máximo (pode ser ajustado conforme necessário)
      }}
    >
      <Box mt={1} p={2} boxShadow={3} bgcolor="white">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={6}>
            <img rel="preload" src="/images/logo-red.webp" alt="FullSatt" style={{ width: "100%", height: "auto" }} />
          </Grid>
          <Grid item xs={4}>
            <img rel="preload" src="/images/sky-logo.webp" alt="Sky" style={{ width: "100%", height: "auto" }} />
          </Grid>
        </Grid>

        {/* <Typography variant="h2" gutterBottom align="center">
          Reporte de Atividades
        </Typography> */}

        <Typography
          variant="h1"
          gutterBottom
          align="center"
          sx={{
            fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem", lg: "3rem" },
          }}
        >
          Reporte de Atividades
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Número da OS"
                name="OSnumber"
                value={formData.OSnumber}
                onChange={handleChange}
                fullWidth
                //margin="dense"
                inputProps={{
                  inputMode: "numeric", // Isso sugere ao navegador abrir o teclado numérico
                  pattern: "[0-9]*", // Isso define um padrão de entrada numérica
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      // borderColor: borderColor,
                      borderRadius: "4px",
                    },
                    "&:hover fieldset": {
                      borderColor: borderColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: borderColor,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                <InputLabel id="select-label">Tipo de Serviço</InputLabel>
                <Select
                  labelId="select-label"
                  id="selectServ"
                  name="selectServico"
                  value={formData.selectServico}
                  onChange={handleChange}
                  label="Tipo de Serviço"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // borderColor: borderColor,
                        borderRadius: "4px",
                      },
                      "&:hover fieldset": {
                        borderColor: borderColor,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: borderColor,
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Selecione um tipo de serviço</em>
                  </MenuItem>
                  <MenuItem value="PP">Instalação PP TV</MenuItem>
                  <MenuItem value="PP+PO">Instalação PP e PO TV</MenuItem>
                  <MenuItem value="PPBL">Instalação PP BL</MenuItem>
                  <MenuItem value="AT">Assistência Técnica</MenuItem>
                  <MenuItem value="PS">Prestação de Serviço</MenuItem>
                  <MenuItem value="ATBL">Assistência Téc. BL</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {formData.selectServico === "AT" && (
              <Grid item xs={12}>
                <Box border={1} borderRadius={1} p={0.5} borderColor="grey.400">
                  <Grid item container alignItems="center" justifyContent="space-between" marginLeft={1}>
                    <Grid item>
                      <Typography variant="h8">Sistema apresentou defeito?</Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        name="apresentouDefeito"
                        value={formData.apresentouDefeito}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                        <FormControlLabel value="nao" control={<Radio />} label="Não" />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Box>

                {formData.apresentouDefeito === "sim" && (
                  <Grid item xs={12}>
                    <Grid marginTop={1}>
                      <FormControl fullWidth margin="dense">
                        <InputLabel id="select-defeitoOnde">Defeito onde?</InputLabel>
                        <Select
                          labelId="select-defeitoOnde"
                          id="selectDefOnde"
                          name="selectDefeitoOnde"
                          value={formData.selectDefeitoOnde}
                          onChange={handleChange}
                          label="Defeito onde?"
                        >
                          <MenuItem value="">
                            <em>Selecione onde o defeito ocorreu</em>
                          </MenuItem>
                          <MenuItem value="SignalCapture">Captação do sinal</MenuItem>
                          <MenuItem value="SignalBrodcast">Distribuição do sinal</MenuItem>
                          <MenuItem value="SignalReceiver">Recepção do sinal</MenuItem>
                          {/* <MenuItem value="AT">Assistência Técnica</MenuItem>
                        <MenuItem value="PS">Prestação de Serviço</MenuItem>
                        <MenuItem value="ATBL">Assistência Téc. BL</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>

                    {formData.selectDefeitoOnde === "SignalCapture" && (
                      <Grid item xs={12}>
                        <Grid marginTop={1}>
                          <Box border={1} borderRadius={1} p={0.5} borderColor="grey.400">
                            <Typography
                              variant="h1"
                              gutterBottom
                              align="center"
                              sx={{
                                fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.5rem", lg: "1.5rem" },
                              }}
                            >
                              Ações Realizadas
                            </Typography>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formData.ApontAntena}
                                    onChange={handleChange}
                                    name="ApontAntena"
                                    disabled={formData.TrocaAntena || formData.TrocaLocAntena} // Desabilita se TrocaAntena estiver marcado
                                  />
                                }
                                label="Apontamento da Antena"
                                sx={{ ml: 2 }} // Adiciona margem à esquerda
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formData.TrocaAntena}
                                    onChange={handleChange}
                                    name="TrocaAntena"
                                    disabled={formData.ApontAntena || formData.TrocaLocAntena} // Desabilita se ApontAntena estiver marcado
                                  />
                                }
                                label="Troca da Antena"
                                sx={{ ml: 2 }} // Adiciona margem à esquerda
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formData.TrocaLocAntena}
                                    onChange={handleChange}
                                    name="TrocaLocAntena"
                                    disabled={formData.ApontAntena || formData.TrocaAntena} // Desabilita se ApontAntena estiver marcado
                                  />
                                }
                                label="Troca de local da Antena"
                                sx={{ ml: 2 }} // Adiciona margem à esquerda
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox checked={formData.TrocaLNB} onChange={handleChange} name="TrocaLNB" />
                                }
                                label="Troca do LNB"
                                sx={{ ml: 2 }} // Adiciona margem à esquerda
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox checked={formData.ConectLNB} onChange={handleChange} name="ConectLNB" />
                                }
                                label="Conector do LNB"
                                sx={{ ml: 2 }} // Adiciona margem à esquerda
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formData.DefOndeOutros}
                                    onChange={handleChange}
                                    name="DefOndeOutros"
                                  />
                                }
                                label="Outras ações"
                                sx={{ ml: 2 }} // Adiciona margem à esquerda
                              />
                            </FormGroup>
                            {formData.DefOndeOutros && (
                              <Grid>
                                <TextField
                                  label="Informe outras ações realizadas"
                                  name="acaoDefeito"
                                  value={formData.acaoDefeito}
                                  onChange={handleChange}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                    {formData.selectDefeitoOnde === "SignalBrodcast" && (
                      <Grid item xs={12}>
                        <Grid marginTop={1}>
                          <Box border={1} borderRadius={1} p={0.5} borderColor="grey.400">
                          <Typography
                              variant="h1"
                              gutterBottom
                              align="center"
                              sx={{
                                fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.5rem", lg: "1.5rem" },
                              }}
                            >
                              Ações Realizadas
                            </Typography>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formData.CabosConectores}
                                    onChange={handleChange}
                                    name="CabosConectores"
                                  />
                                }
                                label="Correção no cabeamento e conexões"
                                sx={{ ml: 2 }} // Adiciona margem à esquerda
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox checked={formData.ChaveseAmplif} onChange={handleChange} name="Chaves" />
                                }
                                label="Troca de chaves comutadoras e/ou Amplificadores"
                                sx={{ ml: 2 }} // Adiciona margem à esquerda
                              />
                            </FormGroup>
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                    {/* <Grid>
                      <TextField
                        label="Detalhes do defeito identificado"
                        name="detalheDefeito"
                        value={formData.detalheDefeito}
                        onChange={handleChange}
                        fullWidth
                        margin="dense"
                      />
                    </Grid> */}
                  </Grid>
                )}
                {formData.apresentouDefeito === "nao" && (
                  <TextField
                    label="Motivo"
                    name="motivoImproc"
                    value={formData.motivoImproc}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                )}
              </Grid>
            )}

            {/* <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Mensagem"
                name="mensagem"
                value={formData.mensagem}
                onChange={handleChange}
                fullWidth
                margin="dense"
                multiline
                rows={4}
              />
            </Grid> */}

            {/* <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.checkboxAntena}
                      onChange={handleChange}
                      name="checkboxAntena"
                    />
                  }
                  label="Cliente acompanhou o atendimento"
                />
              </FormGroup>
            </Grid> */}

            <Grid item xs={12}>
              <Box border={1} borderRadius={1} p={0.5} borderColor="grey.400">
                <Grid item container alignItems="center" justifyContent="space-between" marginLeft={1}>
                  <Grid item>
                    <Typography variant="h8">Foram utilizados materiais?</Typography>
                  </Grid>

                  <Grid item>
                    <RadioGroup
                      name="utilizadoMateriais"
                      value={formData.utilizadoMateriais}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                      <FormControlLabel value="nao" control={<Radio />} label="Não" />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {formData.utilizadoMateriais === "sim" && (
              <Grid item xs={12}>
                <TextField
                  label="Detalhe os materiais utilizados"
                  name="detalheMateriais"
                  value={formData.detalheMateriais}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Box border={1} borderRadius={1} p={0.5} borderColor="grey.400">
                <Grid item container alignItems="center" justifyContent="space-between" marginLeft={1}>
                  <Grid item>
                    <Typography variant="h8">Houve troca de equipamento?</Typography>
                  </Grid>

                  <Grid item>
                    <RadioGroup name="trocaEquipamento" value={formData.trocaEquipamento} onChange={handleChange} row>
                      <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                      <FormControlLabel value="nao" control={<Radio />} label="Não" />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box border={1} borderRadius={1} p={0.5} borderColor="grey.400">
                <Grid item container alignItems="center" justifyContent="space-between" marginLeft={1}>
                  <Grid item>
                    <Typography variant="h8">Cliente ciente do prazo de garantia?</Typography>
                  </Grid>

                  <Grid item>
                    <RadioGroup name="cienteGarantia" value={formData.cienteGarantia} onChange={handleChange} row>
                      <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                      <FormControlLabel value="nao" control={<Radio />} label="Não" />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <input
                accept="image/*"
                capture="environment"
                style={{ display: "none" }}
                id="upload-button-file"
                type="file"
                name="foto"
                onChange={handleChange}
              />
              <label htmlFor="upload-button-file">
                <Button variant="contained" color="primary" component="span" fullWidth>
                  Foto da Antena
                </Button>
              </label>
            </Grid>
            {preview && (
              <Grid item xs={12}>
                <Card>
                  <CardMedia
                    component="img"
                    height="400"
                    image={preview}
                    alt="Pré-visualização"
                    sx={{ objectFit: "contain" }}
                  />
                </Card>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default FormPrincipal;
